@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700;900;1000&family=Poppins:wght@300;500;700;900&family=Quicksand:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@400;500;700;900&family=Ubuntu:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --light-blue: #e9f1fa;
  --dark-blue: #00abe4;
  --darker-blue: #0077ff;
  --cancel-color: #d63b26;
  --white: #fff;
  --bold-text-color: #032350;
  --light-text-color: rgba(31, 48, 71, 0.8);
  --font-family: "Open Sans", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  background-color: var(--light-blue);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--bold-text-color);
}

.page-heading {
  font-size: 2rem;
  text-align: center;
  margin: 0 auto;
}

p {
  color: var(--light-text-color);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 94%;
  margin: 2rem auto;
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.btn {
  min-width: 8rem;
  color: var(--white);
  background-color: var(--dark-blue);
  font-size: 1rem;
  padding: 0.4rem 0.6rem;
  border: none;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-family);
}

.btn-cancel {
  min-width: 8rem;
  color: var(--white);
  background-color: var(--cancel-color);
  font-size: 1rem;
  padding: 0.6rem 0.8rem;
  border: none;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-family);
}

.btn-filter {
  color: var(--white);
  background-color: var(--darker-blue);
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  border: none;
  letter-spacing: 2px;
  border-radius: 20px;
  cursor: pointer;
}

.btn-filter + .btn-filter {
  background-color: var(--dark-blue);
  margin-left: 1rem;
}

.btn-detail{
  background-color: #0077ff;
}

.btn:hover, 
.btn-filter:hover,
.btn-cancel:hover {
  opacity: 0.8;
}

.disabled {
  cursor: auto;
}

.disabled:hover {
  background-color: var(--dark-blue);
}

.layout-container {
  display: flex;
  gap: 1rem;
}

.loader {
  width: 80vw;
  height: 90vh;
  display: flex !important;
  margin: auto;
}

.nothing {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgba(30, 55, 90, 0.3);
  height: 100%;
}

.spiner {
  margin-top: 2rem;
  border: 4px solid var(--darked-blue); ; 
  border-top: 4px solid var(--dark-blue); 
  border-radius: 50%; 
  width: 50px; 
  height: 50px; 
  animation: spin 1s linear infinite; 
}

.search-input {
  padding: 0.5rem;
  border: 1px solid var(--dark-blue);
  border-radius: 4px;
  font-size: 1rem;
  width: 40%;
}

.search-input:active,
.search-input:focus-visible{
  outline: var(--darked-blue);
  outline: red;
}

.form-input,
textarea,
select {
  font-size: 1rem;
  background-color: #d5d5d5;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-family: var(--font-family);
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.page-item {
  list-style: none;
  margin: 0 0.25rem;
}

.page-link {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #f8f9fa;
  text-decoration: none;
  color: #007bff;
}

.page-link:hover {
  background-color: #e9ecef;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  cursor: not-allowed;
  color: #6c757d;
  background-color: #f8f9fa;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 1024px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 896px) {
  html {
    font-size: 85%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 80%;
  }
  .btn{
    font-size: 0.9rem;
    padding: 0.6rem 0.6rem;
  }
  .search-input{
    width: 80%;
  }
}

@media (max-width: 596px) {
  html {
    font-size: 70%;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 60%;
  }
}
