header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  position: sticky;
  top: 0%;
  left: 0%;
  background-color: var(--light-blue);
  z-index: 2;
  box-shadow: 0 0 20px 0 rgba(144, 174, 205, 0.339);
  transition: all 0.2 linear;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
}
.menu-dropdown {
  display: none;
}

.nav-logo{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 800;
}


.header_logo{
  width: 20rem;
}

.nav-logo a,
.nav-links a {
  color: var(--bold-text-color);
}

.nav-links {
  display: flex;
  gap: 2rem;
  font-size: 1.1rem;
}

.nav-links .btn {
  color: var(--white);
  font-size: 1rem;
  padding: 0.3rem 0.6rem;
}

.menu-icons {
  display: none;
  z-index: 100;
  font-size: 1.6rem;
  position: relative;
}

.menu-dropdown {
  display: none;
  width: 120%;
  position: absolute;
  top: 24px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  z-index: 999;
}

.menu-dropdown .nav-dropdown {
  font-size: 1rem;
}


.menu-dropdown li{
  padding: 8px 12px;
  text-align: start;
}

.menu-dropdown li:hover{
  opacity: 0.9;
}

.user-menu {
  position: relative;
}

.nav-links li:hover .menu-dropdown {
  display: block;
}


.menu-open,
.menu-close {
  position: absolute;
  color: var(--black);
  cursor: pointer;
}

.title-1{
  color: #24be0f;
}

.title-2{
  color: #e7820f;
}

@media (max-width: 1300px) {
  nav {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (max-width: 950px) {
  .nav-links {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: var(--light-blue);
    flex-direction: column;
    transform: translateX(100%);
    justify-content: center;
    align-items: center;
    visibility: hidden;
    z-index: 20;
    gap: 3rem;
    transition: all 0.3s linear;
  }
  .nav-active .nav-links {
    transform: translateX(0%);
    visibility: visible;
  }
  .menu-icons {
    display: block;
    position: absolute;
    top: 33%;
    right: 7%;
  }
}
