@import url(app.css);

.register-section {
  width: 100%;
  height: 100vh;
}

.register-container {
  flex-direction: column;
  gap: 2rem;
  width: 30%;
}

.form-heading {
  font-size: 2rem;
}

.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login-link {
  color: var(--darker-blue);
}

@media (max-width: 1184px) {
  .register-container {
    width: 30%;
  }
}

@media (max-width: 1120px) {
  .register-container {
    width: 35%;
  }
}

@media (max-width: 746px) {
  .register-container {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .register-container {
    width: 60%;
  }
}

@media (max-width: 408px) {
  .register-container {
    width: 70%;
  }
}
